//SCSS variable configuration to include styles for creative refresh 2018.
$cr18: true;
// There is no offer banner so the height should be 0
$offerbar-height: 0;

//SCSS variable configuration to include styles for nav creative refresh 2020.
$cr20_nav_refresh: true;

// SCSS variable configuration to include styles for desktop nav creative refresh 2020.
$cr20_desktop_nav_refresh: true;

//SCSS variable configuration to include v3 shared account styles
$perlgem_shared_account_v3_styles: true;
