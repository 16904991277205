.product-grid-wrapper {
  .product-brief__header {
    .review-snippet {
      display: block !important;
      .cross-sells & {
        display: block;
      }
    }
  }
}

#pr-write {
  .p-w-r {
    select {
      &#pr-efectiveness {
        padding-right: 30px !important;
      }
    }
  }
}

.section-mask-finder {
  .product-grid-wrapper {
    .product-brief__header {
      .review-snippet {
        @include breakpoint($small-down) {
          position: relative;
          left: 39%;
        }
      }
    }
  }
}
