.product-full {
  .afterpay-paragraph {
    .Clearpay-logo {
      vertical-align: sub !important; //!important is used to override the inline styles from 3rd party content
    }
  }
}

// Endeca Updated Search
.utility-nav-active,
.enable-endeca__search {
  .search_results {
    &-filters-text {
      display: inline;
    }
    &__filter_clear {
      p {
        a.button {
          width: auto;
        }
      }
    }
  }
}
.account-adyen-add-payment {
  .account-page {
    margin-top: 13px;
  }
  .payment_address {
    border: none;
    width: 100%;
    @include breakpoint($medium-up) {
      width: 50%;
    }
    .adyen-fields {
      .adyen-checkout {
        &__input {
          border-radius: 0;
          height: 48px;
          @include breakpoint($medium-up) {
            height: 56px;
          }
        }
      }
    }
    .payment-form {
      &__adyen {
        &--option {
          width: 100%;
          margin-top: 5px;
        }
      }
    }
  }
  .non-adyen-fields {
    .payment-address {
      @include breakpoint($medium-up) {
        width: 50%;
      }
      .selectbox {
        @include swap_direction(margin, 10px);
        width: 90%;
      }
    }
  }
  .payment-submit {
    border: none;
  }
}

.adyen_enabled {
  #review-panel {
    .address {
      .view-address {
        a.change-address {
          font-family: #{$font--futura-book};
        }
      }
    }
    .checkout__panel--header {
      clear: both;
    }
    .content {
      .checkout__panel {
        padding-#{$ldirection}: 18px;
        padding-#{$rdirection}: 10px;
        @include breakpoint($medium-up) {
          padding-#{$rdirection}: 0;
        }
      }
    }
  }
  .gift-options-display {
    padding-#{$ldirection}: 0;
  }
  .sub-section {
    &.address {
      border-bottom: 1px solid #{$color-shade-dark-grey};
      margin-bottom: 10px;
      .view-address {
        padding-bottom: 17px;
        .change-address {
          float: #{$rdirection};
          color: #{$color-black};
          margin-#{$rdirection}: 10px;
        }
        .new-address {
          margin-bottom: 10px;
        }
        .payment-address {
          margin-top: 5px;
        }
      }
    }
    .set-address {
      margin-bottom: 15px;
    }
  }
  .payment_options-terms {
    display: none;
    .payment-terms {
      font-size: 15px;
      text-transform: none;
      .terms-overlay-link-cms {
        text-decoration: underline;
        text-transform: none;
        font-weight: normal;
      }
      .overlay-wrapper {
        display: inline-block;
      }
    }
    .clearpay-button {
      max-width: 400px;
      button {
        &:empty {
          display: none;
        }
      }
    }
    &.paypal-terms {
      position: relative;
      z-index: 1;
      .paypal-button {
        display: inline-block;
      }
    }
  }
  .payment-option {
    border-bottom: 1px solid #{$color-black};
    @include swap_direction(padding, 20px);
    &:first-child {
      border-top: 1px solid #{$color-shade-dark-grey};
    }
    .field {
      &:checked {
        & + label {
          .payment_options-terms {
            display: block;
          }
        }
      }
    }
    .clear-pay-image {
      vertical-align: top;
    }
    .afterpay-button {
      background: #{$color-black};
      border: none;
      font-size: 12px;
      color: #{$color-white};
      width: 100%;
      max-width: 400px;
      height: 44px;
      .clearpay-adyen-logo {
        vertical-align: middle;
      }
    }
  }
  .chckt-pm {
    border: none;
    &__header {
      padding-#{$ldirection}: 0;
    }
    &__image {
      margin-#{$ldirection}: 0;
    }
    &__details {
      padding-#{$ldirection}: 5px;
      padding-bottom: 0;
    }
    &__name {
      font-size: 16px;
    }
  }
  .chckt-form__title,
  .chckt-form-label__text {
    color: #{$color-black};
    font-weight: normal;
    font-size: 16px;
  }
  .chckt-input-field {
    @include swap_direction(padding, 6px 10px 5px 10px);
    border-radius: 0;
    height: 44px;
    border-color: #{$color-shade-dark-grey};
    width: 100%;
    &--recurring {
      border: 1px solid #{$color-shade-dark-grey};
    }
    &--error {
      color: #{$color-red};
      border: 1px solid #{$color-red};
    }
  }
  .chckt-pm-list__button {
    max-width: 387px;
  }
  .chckt-checkbox {
    position: static;
  }
  .chckt-button-container {
    margin-bottom: 30px;
    @include breakpoint($medium-up) {
      max-width: 380px;
    }
  }
  .chckt-button {
    @include swap_direction(padding, 13px 0);
    background-color: #{$color-black};
    &--disabled {
      background-color: #{$color-shade-dark-grey};
    }
  }
  .chckt-form-label__error-text {
    color: #{$color-red};
    text-transform: none;
    font-weight: normal;
  }
  .all_card_img {
    height: 24px;
    margin-#{$ldirection}: 10px;
    vertical-align: top;
  }
  .paypal-adyen-logo {
    vertical-align: middle;
  }
  .clearpay-adyen-logo {
    @include swap_direction(margin, 0 5px);
    vertical-align: top;
    height: 26px;
  }
  .adyen-payment {
    .chckt-form-label {
      &--exp-date {
        width: 46%;
      }
      &--cvc {
        width: 50%;
        padding-#{$ldirection}: 22px;
      }
    }
    .chckt-input-field {
      &--cvc {
        width: 100%;
        max-width: 174px;
      }
    }
    &.edit {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .content {
      .adyen-show-help-panel {
        .overlay-wrapper {
          display: inline-block;
        }
      }
    }
    .chckt-more-pm-button {
      @include swap_direction(padding, 0);
      border: none;
      &__icon {
        display: none;
      }
      &__text {
        font-size: 14px;
        color: #{$color-black};
        text-decoration: underline;
        margin-#{$ldirection}: 0;
      }
    }
  }
  .local-submits {
    .pc_hidden {
      display: none;
    }
  }
}

.paypal-processing {
  @include swap_direction(padding, 10px);
  @include swap_direction(margin, 0 auto);
  #{$ldirection}: 0;
  position: fixed;
  #{$rdirection}: 0;
  text-align: center;
  top: 10vw;
  width: 100%;
  z-index: 999;
  opacity: 1;
  .process-content {
    @include swap_direction(padding, 30px 10px);
    @include swap_direction(margin, 0 auto);
    background: #{$color-white};
    max-width: 335px;
    z-index: 999;
    position: relative;
    opacity: 1;
  }
  .paypal-logo {
    padding-top: 20px;
    width: 90px;
  }
  .origins-logo-processing {
    padding-bottom: 25px;
  }
  .paypal-overlay {
    background: #{$color-black};
    opacity: 0.7;
    #{$ldirection}: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
}

.adyen-viewcart-buttons-panel {
  .continue-buttons {
    position: relative;
    z-index: 1;
    .paypal-smart-button {
      clear: both;
      margin-#{$rdirection}: 10px;
      padding-top: 10px;
      display: inline-block;
    }
  }
}

.helpful-links-overlay {
  .cs-page {
    &__content {
      width: 100%;
    }
  }
}
.cart-item {
  &.cart-item-kit {
    .cart-item {
      &__cart-details {
        display: block;
        float: #{$rdirection};
      }
    }
  }
}

.page-header {
  &__top-bar {
    z-index: 14;
  }
}

.kit-formatter {
  &.product-full {
    .product-kit__container {
      .product-full {
        &__carousel-thumbs {
          display: none;
        }
        &__image-container {
          margin-#{$ldirection}: 0;
          padding-#{$ldirection}: 0;
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .mobile_hidden {
    display: none !important;
  }
}

@include breakpoint($medium-up) {
  .pc_hidden {
    display: none !important;
  }
}

//Clearpay popup override
#afterpay-modal-overlay {
  #afterpay-modal-modal {
    height: 95%;
    width: 95%;
    @include breakpoint($medium-up) {
      height: 80%;
      width: 60%;
    }
    #afterpay-modal-close {
      min-width: 5%;
    }
  }
}
