.appt-book {
  .appt-book-page-header {
    .appt-book-page-header-content__title {
      margin-bottom: 1em;
      font-size: 1.75em;
    }
  }
  .appt-book-sections-container {
    .appt-book-section {
      .location-selector {
        .appt-book__tout--sign-in {
          margin-top: 29px;
          font-size: 17px;
        }
      }
    }
    .location-select-container {
      .selectBox-label {
        font-size: 13px;
      }
      .selectBox {
        overflow: auto;
      }
    }
    .appt-book-section-content {
      .service {
        height: 300px !important;
      }
      #booking-step3.change-password {
        .password-field__info::before {
          top: -13px;
        }
        .password-field__info {
          margin-top: 5px;
          top: 97%;
        }
        .password-field__rules {
          padding: 5px;
        }
      }
    }
  }
  #confirm {
    .registration-mobile-phone {
      .signup__mobile-prefix {
        width: 20% !important;
        text-align: center;
        padding: 0;
        float: left;
      }
      #registration-mobile-phone {
        float: right;
        width: 80% !important;
      }
    }
  }
  .appt-book.confirm {
    #booking-step3.change-password {
      .password-field__info {
        top: -315%;
        left: 13px;
      }
    }
  }
}

.appt-book.confirm {
  .confirm-container {
    #book-appt-container {
      #book-appt-fieldset {
        @include breakpoint($medium-only) {
          input {
            padding: 4px 0.5em;
          }
        }
      }
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.appt-book.appointments {
  #booking-step1 {
    .services {
      .service {
        .selection-service {
          @include breakpoint($medium-only) {
            .service-head {
              line-height: 25px;
            }
          }
        }
      }
    }
  }
  #booking-step2 {
    .appointment-select {
      .calendar-head {
        .appt-book-datepicker-overlay {
          .overlay-content-container {
            .calendar_wrapper {
              .pika-table {
                tbody {
                  height: 19em;
                }
              }
            }
          }
        }
      }
      .calendar_wrapper {
        table {
          tbody {
            display: list-item;
            overflow: scroll;
          }
        }
      }
    }
  }
}
