.password-field {
  position: relative;
  &__info {
    margin-top: 5px;
    @include breakpoint($medium-up) {
      border: 1px solid #{$color-dark-gray};
      width: auto;
      position: absolute;
      top: -158%;
      left: 0;
    }
    .password-reset-page & {
      @include breakpoint($medium-up) {
        top: 9%;
        left: 98%;
        &::before {
          top: 45%;
          right: 100%;
          border-color: transparent #{$color-black} transparent transparent;
        }
      }
    }
    &::before {
      @include breakpoint($medium-up) {
        content: '';
        position: absolute;
        top: 100%;
        right: 96%;
        border-width: 5px;
        border-style: solid;
        border-color: #{$color-black} transparent transparent transparent;
      }
    }
    .checkout-confirmation-page & {
      @include breakpoint($medium-up) {
        left: 10%;
        &::before {
          right: 53%;
        }
      }
    }
    .password-field__rules {
      padding: 10px;
      background-color: #{$color-white};
      column-count: 2;
      li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 3px 0;
        color: #{$color-black};
        transition: 0.2s;
        white-space: nowrap;
        line-height: 1.5;
        font-size: 11px;
        letter-spacing: initial;
        &.pass {
          color: #{$color-dark-green};
          &::before {
            color: #{$color-dark-green};
            content: '\2713';
            text-shadow: 0 0 8px #{$color-black};
          }
        }
        &::before {
          content: '\2715';
          display: inline-block;
          color: #{$color-red};
          font-size: 1em;
          line-height: 0;
          margin: 0 6px 0 0;
          transition: 0.2s;
        }
      }
    }
  }
}
