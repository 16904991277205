.payment-form fieldset .form-item select {
  width: 50%;
}

.account-page__content .account-products__item {
  &--desc {
    width: 20%;
  }
  &--thumb {
    width: 10%;
  }
  .product__tracking {
    width: 20%;
  }
  .product__status {
    width: 20%;
    float: left;
    margin-left: 0;
  }
  .product__price {
    width: 10%;
  }
  .product__qty {
    width: 10%;
  }
  .product__total {
    width: 10%;
    text-align: left;
  }
}

.orders-list {
  .order-number {
    position: relative;
    .tracking_disclaimer {
      display: none;
      max-width: 250px;
    }
    .link:hover .tracking_disclaimer {
      display: block;
      background-color: $color-white;
      color: $color-black;
      border: 1px solid $color-black;
      border-radius: 6px;
      font-size: 11px;
      width: 195px;
      text-transform: unset;
      position: absolute;
      bottom: 23px;
      padding: 3px;
      z-index: 1;
    }
  }
  .order-info {
    overflow: visible;
  }
}

.order-details-page {
  .order-details-page__content {
    .order-products {
      .order-products__header {
        @include breakpoint($medium-only) {
          display: block;
          font-family: $font--futura-demi;
          text-transform: uppercase;
          font-size: 24px;
        }
        &--product {
          width: 40%;
        }
        &--price {
          width: 20%;
        }
        &--qty {
          width: 20%;
        }
        &--total {
          width: 20%;
          text-align: left;
        }
      }
      .account-products__item {
        @include breakpoint(($medium-up) ($medium-down)) {
          .cart-item__label {
            display: none;
          }
        }
        .account-products__item--thumb {
          width: 10%;
        }
        .account-products__item--desc {
          width: 30%;
        }
        .product__price {
          width: 20%;
          @include breakpoint(($medium-up) ($medium-down)) {
            margin-left: 35%;
          }
        }
        .product__qty {
          width: 20%;
          text-align: left;
          padding-left: 3.2em;
          @include breakpoint(($medium-up) ($medium-down)) {
            p {
              font-family: $font--futura-book;
              line-height: 1.8;
              font-size: 24px;
              margin-top: -40px;
            }
          }
        }
        .product__total {
          width: 20%;
          @include breakpoint(($medium-up) ($medium-down)) {
            margin-left: auto;
            margin-top: -60px;
          }
        }
      }
    }
    .order-totals {
      padding-left: 45%;
      padding-right: 13%;
    }
  }
}

.sign-in-page {
  .new-account {
    .account-page__section--heading {
      text-align: center;
    }
    .password-field {
      overflow: visible;
      &__form-item {
        position: relative;
        overflow: visible;
      }
    }
  }
}

.gift-overlay {
  #cboxLoadedContent {
    overflow: hidden !important;
  }
}

.account-page__content {
  .orders-list {
    &__table--cell {
      width: 20%;
    }
    .orders-list__table--group {
      .order-toggle {
        width: 10%;
      }
    }
    .account-products__item {
      .product__status {
        width: auto;
      }
      .account-products__item--desc {
        width: 35%;
      }
      .product__qty {
        width: 35%;
      }
    }
  }
  .orders-list {
    .orders-list__table {
      .order-details {
        .product__actions {
          width: 20%;
        }
      }
      .kit {
        border-bottom: 0px !important;
      }
    }
  }
}

.page-product {
  #colorbox {
    #email_a_friend_container {
      padding-left: 5px;
      #email_a_friend {
        .row {
          margin-bottom: 5px;
          label {
            width: 200px;
            display: inline-block;
          }
          input {
            width: 300px;
          }
          textarea {
            width: 300px;
          }
          .inline {
            padding-left: 215px;
            span {
              width: 340px;
            }
          }
          input[type='submit'] {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.address-overlay {
  &.adpl {
    padding-right: 0;
  }
}

.email_friend-form__container {
  padding: 16px;
  font-size: 15px;
  h2 {
    text-align: center;
    text-transform: uppercase;
    font-family: $font--futura-demi;
    font-size: 30px;
  }
  .label-input {
    padding: 0 15px 20px 0;
    float: left;
    width: 50%;
    input {
      height: 45px;
      width: 100%;
    }
    textarea {
      height: 120px;
      width: 100%;
    }
  }
  .label-message {
    width: 100%;
  }
  .label-friend_consent {
    padding: 0 0 15px 0;
    float: left;
    width: 75%;
  }
  .label-send_copy {
    padding: 0 0 15px 6px;
    float: left;
    width: 25%;
  }
  .disclaimer {
    float: left;
  }
  .btn_submit {
    width: 100%;
    float: none;
    max-width: 320px;
    margin: 0px 90px auto auto;
    input[type='submit'] {
      width: 65%;
    }
  }
  .success_message {
    padding: 16px;
    font-size: 15px;
    p {
      text-align: center;
    }
  }
}

.spp-page {
  .product-social__icon {
    .icon--email {
      cursor: pointer;
    }
  }
}

@include breakpoint($landscape-up) {
  .regimen-quiz {
    .regimen-quiz__grouping--age {
      width: 6%;
    }
    .regimen-quiz__grouping--gender {
      width: 25%;
    }
    .regimen-quiz__fieldset--skin-type {
      &__product-attribute {
        width: 31%;
      }
    }
  }
}

@media (width: $medium-up) {
  .profile-page {
    #profile_preferences {
      .profile-my-store {
        &__store {
          padding-left: 0px;
          padding-top: 310px;
          &-info {
            margin-left: 0px;
            min-height: 150px;
          }
          &-actions {
            margin-left: 1px;
          }
        }
      }
    }
  }
}

@media (min-width: $medium-up) and (max-width: $landscape-up) {
  .cs-page__content {
    .content-container {
      .expando-block__header {
        .expando-block__title--pc {
          display: block;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .product-grid__item,
  .favorites__item {
    float: left;
    width: 33.333%;
  }
}

.section-products {
  .selectBox-dropdown-menu {
    max-height: 237px !important;
  }
}

.page-footer {
  .page-footer__secondary {
    padding-bottom: 40px;
  }
}

.email_promotions.phone2 {
  .sms-info__item {
    padding-top: 2%;
  }
}

.gnav-util--account {
  .signin-gnav-link {
    display: none;
  }
}

.section-customer-service-ordering {
  .page-main__inner {
    .cs-page__navigation {
      .cs-contacts {
        padding-left: 0.5em;
        padding-right: 0.5em;
      }
    }
  }
}

.beyond-the-basics {
  .product-brief__shades {
    .slick-next {
      left: 206px;
    }
  }
}
