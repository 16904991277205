.section-store-locator {
  .store-locator__map-container {
    .gmnoprint {
      div[title^='Pan left'],
      div[title^='Pan down'],
      div[title^='Pan right'],
      div[title^='Pan up'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
  #store_results {
    .store-locator__result {
      .store-locator__result-name {
        width: 85px;
        margin-right: 0;
        font-size: 13px;
      }
    }
  }
  .store-locator__main {
    .store-locator__filter {
      display: none !important;
    }
  }
}
/* donations */
.donations.panel {
  float: left;
  width: 100%;
  .content {
    float: left;
    width: 100%;
    .donation_info_panel {
      width: 100%;
      float: left;
      padding: 1% 0%;
      .donation_logo {
        float: left;
        height: 100%;
        padding: 0px 20px 0px 0px;
      }
      .donation_logo_mobile {
        display: none;
        float: left;
        height: 100%;
      }
      .donation_message {
        font-size: 16px;
      }
      .donation_message_mobile {
        display: none;
        margin: 0% 0% 0% 25%;
        font-size: 14px;
      }
    }
    .donation_amounts_panel {
      float: left;
      width: 100%;
      h2.donation-panel__title {
        text-align: right;
        border-bottom: none;
        margin-bottom: 0px;
        background-color: #eff0f0 !important;
        margin: 2% 0%;
        letter-spacing: 0.1em;
        width: 100%;
        padding: 2% 3% 3% 0%;
        #select-amount {
          font-size: 20px;
          padding: 8px 0px 0px 45px;
          float: left;
        }
        #select-amount-mobile {
          display: none;
        }
        span.donation-group {
          label {
            font-size: 15px;
            vertical-align: bottom;
          }
        }
      }
    }
  }
}

#confirmation-page {
  #confirmation-panel {
    .checkout__panel--content {
      .confirmation-panel__confirm-text--donation {
        margin-bottom: 20px;
        background-color: #f0f0f0;
        padding: 0% 0% 2% 3%;
        margin-top: 15px;
        h2.checkout__subtitle {
          font-size: 1.4em;
          font-family: $font--futura-medium;
          letter-spacing: 0.03em;
          padding: 2% 0% 0% 0%;
        }
      }
    }
  }
}

#viewcart-panel {
  .product.donation {
    a.remove-donation {
      margin-top: 4em;
      display: block;
      text-decoration: underline;
      width: 55px;
    }
  }
}

.product-full {
  .afterpay-link {
    font-size: 20px;
  }
}
