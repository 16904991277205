.store-locator {
  &__result {
    &[data-store-id='BOOTS'],
    &[data-store-id='JOHN LEWIS'],
    &[data-store-id='HOUSE OF FRASER'],
    &[data-store-id='DEBENHAMS'],
    &[data-store-id='SELFRIDGES'] {
      .store_name .marker {
        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
    &[data-store-id='BOOTS'] &-name,
    &[data-store-id='JOHN LEWIS'] &-name,
    &[data-store-id='HOUSE OF FRASER'] &-name,
    &[data-store-id='DEBENHAMS'] &-name,
    &[data-store-id='SELFRIDGES'] &-name {
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
    &[data-store-id='BOOTS'] &-name {
      background-image: url('/media/images/locator/boots_logo_75x70.jpg');
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/boots_logo_75x70.jpg', sizingMethod='scale');
      -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/boots_logo_75x70.jpg', sizingMethod='scale')";
    }
    &[data-store-id='JOHN LEWIS'] &-name {
      background-image: url('/media/images/locator/john-lewis_logo_75x70.jpg');
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/john-lewis_logo_75x70.jpg', sizingMethod='scale');
      -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/john-lewis_logo_75x70.jpg', sizingMethod='scale')";
    }
    &[data-store-id='HOUSE OF FRASER'] &-name {
      background-image: url('/media/images/locator/house-of-fraser_logo_75x70.jpg');
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/house-of-fraser_logo_75x70.jpg', sizingMethod='scale');
      -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/house-of-fraser_logo_75x70.jpg', sizingMethod='scale')";
    }
    &[data-store-id='DEBENHAMS'] &-name {
      background-image: url('/media/images/locator/debenhams_logo_75x70.jpg');
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/debenhams_logo_75x70.jpg', sizingMethod='scale');
      -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/debenhams_logo_75x70.jpg', sizingMethod='scale')";
    }
    &[data-store-id='SELFRIDGES'] &-name {
      background-image: url('/media/images/locator/selfridges_logo_75x70.jpg');
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/selfridges_logo_75x70.jpg', sizingMethod='scale');
      -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/selfridges_logo_75x70.jpg', sizingMethod='scale')";
    }
  }
  &__main {
    padding-top: 0;
  }
  &__controls {
    position: relative;
  }
}

.search_results__container {
  .search_results__breadcrumbs {
    .breadcrumb {
      .close_x {
        display: inline;
      }
    }
  }
}

.livechat-recommended-product {
  .livechat-product-item {
    .product-info {
      .product-image {
        width: 200px;
        &__link {
          display: block;
          width: 90px;
          float: right;
        }
      }
      &__actions {
        .livechat-add-to-cart {
          margin-left: 205px;
        }
      }
    }
  }
}
/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  margin-bottom: 20px;
  width: 100%;
  &.photo-tip {
    padding-top: 5px;
  }
  a {
    text-decoration: none;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    max-width: 400px;
    background-color: $color-white;
    color: $color_fuscous_gray;
    border: 1px solid $ab--color--gray-light;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltiptext-under {
    top: 125%;
  }
  .tooltiptext-over {
    bottom: 125%;
  }
  .tooltiptext-under::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $color_fuscous_gray transparent;
  }
  .tooltiptext-over::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: $color_fuscous_gray transparent transparent transparent;
  }
  &:hover .tooltiptext-under,
  &:hover .tooltiptext-over {
    visibility: visible;
    opacity: 1;
  }
  &.gdpr_skin_tool {
    max-width: 300px;
  }
}
