/* adaptive placeholders has a border-color which override the error colour unless the latter is made !important */
input[type='text'].error,
input[type='email'].error,
input[type='tel'].error,
input[type='search'].error,
input[type='password'].error,
textarea.error {
  border-color: $color-red !important;
}

.checkout.viewcart {
  .tab-bar {
    margin-left: 0.5em;
    border-bottom: 2px solid #040a2b;
    margin-bottom: 0px;
    padding-bottom: 0px;
    display: block;
    overflow: hidden;
  }
  .tab-bar {
    margin-left: 0;
    border-top: 1px solid $color-light-gray;
    border-bottom: 1px solid $color-light-gray;
    li {
      margin-right: 0;
      float: left;
      display: inline-block;
      a {
        border-width: 0;
        display: block;
        padding: 0.5em 0.5em;
        color: $color-light-gray;
        text-decoration: none;
        font-family: $font--futura-medium;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-size: 20px;
      }
      a.active {
        border-width: 0;
        color: $color-darker-gray;
        font-family: $font--futura-medium;
        background-color: $color-light-green;
      }
    }
  }
  .tab-content {
    clear: both;
    .continue-shopping-outer-container {
      display: none;
    }
    #samples-panel {
      padding: 1em;
    }
  }
  .tab-pane {
    opacity: 0;
    height: 0;
    overflow: hidden;
    -webkit-transition: opacity 1s ease;
    -moz-transition: opacity 1s ease;
    -o-transition: opacity 1s ease;
    -ms-transition: opacity 1s ease;
    transition: opacity 1s ease;
    header {
      display: none;
    }
  }
  .tab-pane.active {
    display: block;
    opacity: 1;
    height: auto;
  }
  .checkout__content {
    #viewcart-panel {
      padding: 0 0 1em 0;
      .single-message {
        padding-bottom: 15px;
      }
      .content {
        padding-left: 10px;
      }
      .messages {
        padding-bottom: 0px !important;
      }
    }
    .checkout__panel {
      .shipment-option {
        float: left;
        width: 45%;
        a {
          padding-left: 12px;
        }
      }
    }
  }
  .cart-items {
    &__item--thumb {
      width: 20%;
    }
    &__item--desc {
      width: 49%;
    }
  }
  .donations {
    border-top: 1px solid $color-light-gray;
  }
  #checkout_shipmethod {
    .ship-method-collection-group {
      .form-item {
        text-align: justify;
        margin-left: 46%;
      }
    }
  }
}

div.column section.panel div.form_element.onoffswitch {
  position: relative;
  width: 250px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

div.form_element.onoffswitch input[type='checkbox'] {
  height: 2.2em;
  padding: 0.2em 0.6em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  position: relative;
}

div.form_element.onoffswitch input[type='checkbox'] ~ label,
div.form_element.onoffswitch input[type='checkbox'] ~ .label {
  display: block;
}

div.form_element.onoffswitch input[type='checkbox'] ~ label::before,
div.form_element.onoffswitch input[type='checkbox'] ~ .label::before {
  content: none;
}

.onoffswitch-checkbox {
  display: none !important;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 13px;
  color: $color-white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:after {
  content: 'Slide right to donate 1 Euro';
  padding-right: 10px;
  color: #838279;
  text-shadow: 1px 1px $color-white;
  background: #c0c0c1;
  background: -moz-linear-gradient(top, #c0c0c1 0, #e9e9ea 26%, $color-white 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #c0c0c1),
    color-stop(26%, $color-white),
    color-stop(100%, #e9e9ea)
  );
  background: -webkit-linear-gradient(top, #c0c0c1 0, #e9e9ea 26%, $color-white 100%);
  background: -o-linear-gradient(top, #c0c0c1 0, #e9e9ea 26%, $color-white 100%);
  background: -ms-linear-gradient(top, #c0c0c1 0, #e9e9ea 26%, $color-white 100%);
  background: linear-gradient(to bottom, #c0c0c1 0, #e9e9ea 26%, $color-white 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c0c0c1',endColorstr='#e9e9ea',GradientType=0);
  text-align: right;
}

.onoffswitch-inner:before {
  content: 'Thank you for donating!';
  padding-left: 5px;
  text-shadow: 1px 1px #559bb4;
  color: $color-white;
  text-align: left;
  background: #5eaec2;
  background: -moz-linear-gradient(top, #5eaec2 0, #6fcde4 26%, #6fcde4 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #5eaec2),
    color-stop(26%, #6fcde4),
    color-stop(100%, #6fcde4)
  );
  background: -webkit-linear-gradient(top, #5eaec2 0, #6fcde4 26%, #6fcde4 100%);
  background: -o-linear-gradient(top, #5eaec2 0, #6fcde4 26%, #6fcde4 100%);
  background: -ms-linear-gradient(top, #5eaec2 0, #6fcde4 26%, #6fcde4 100%);
  background: linear-gradient(to bottom, #5eaec2 0, #6fcde4 26%, #6fcde4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5eaec2',endColorstr='#6fcde4',GradientType=0);
}

.onoffswitch-switch {
  display: block;
  width: 60px;
  margin: 0;
  height: 30px;
  background: #5680ac;
  background: -moz-linear-gradient(top, #5680ac 0, #1e3a5c 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #5680ac),
    color-stop(100%, #1e3a5c)
  );
  background: -webkit-linear-gradient(top, #5680ac 0, #1e3a5c 100%);
  background: -o-linear-gradient(top, #5680ac 0, #1e3a5c 100%);
  background: -ms-linear-gradient(top, #5680ac 0, #1e3a5c 100%);
  background: linear-gradient(to bottom, #5680ac 0, #1e3a5c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5680ac',endColorstr='#1e3a5c',GradientType=0);
  border: 2px solid #5a7ea9;
  border-radius: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox.checked ~ .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox.checked ~ .onoffswitch-label .onoffswitch-switch {
  left: 190px;
}

div.form_element.onoffswitch {
  position: relative;
  width: 250px;
}

#rev_div2 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

.checkout__content .cart-items__header {
  padding: 0 1em 0 0em;
  &--products {
    width: 70%;
    padding: 1em;
    padding-right: 20%;
    text-align: center;
  }
}

#shipmethod-panel .ship-method-group-label input {
  float: right;
  height: auto;
  margin-right: 5px;
  margin-left: 15px;
}

#shipmethod-panel .ship-method-group-label label {
  float: right;
  line-height: 1.2em;
}

.ship-method-group-label label {
  display: inline-block;
  float: right;
  padding: 1em 0.5em;
}

#shipmethod-panel .ship-method-group {
  width: 100%;
  padding: 0 10px;
}

.ship-method-home-group-container {
  width: 50%;
  float: right;
  padding-right: 0.5em;
}

.ship-method-collection-group {
  text-align: right;
  padding-right: 0.5em;
}

.checkout__sidebar {
  .product-list {
    text-align: center;
    li {
      margin-bottom: 15px;
    }
    .product-name {
      font-family: $font--heading;
      letter-spacing: 0;
      font-weight: normal;
      line-height: 1.2;
      a {
        color: $color-light-green;
        text-decoration: none;
      }
    }
    .product-subhead {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: $font--subheading;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-weight: normal;
      line-height: 1;
      float: none;
      width: auto;
      font-size: 24px;
      margin: 0;
      a {
        color: $color-darker-gray;
        text-decoration: none;
      }
    }
    .price {
      font-size: 24px;
      letter-spacing: -0.01em;
      line-height: 1;
      display: block;
      margin: 0 0 13px;
      font-family: $font--futura-book;
      font-style: italic;
      font-weight: normal;
    }
  }
  .checkout__panel {
    .shipment-option {
      label {
        a {
          padding-left: 12px;
        }
      }
      input[type='radio'] ~ label::before {
        margin-right: 5px !important;
      }
    }
  }
}

.donations .content {
  padding: 1em;
}

.tab-switcher {
  #samples-panel {
    .samples-panel__content {
      width: 100%;
    }
    .product-list li.product {
      width: 100%;
      min-height: 0;
      .product-img {
        img {
          width: 74px;
          height: 85px;
        }
      }
      .details {
        margin-left: 0px;
        float: right;
        text-align: left;
        width: 58%;
      }
    }
    .checkout__panel--heading {
      padding-left: 0px;
    }
  }
  .tab-bar {
    li {
      width: 33%;
    }
    .two-pane {
      width: 50%;
    }
  }
}

.ship_group_1 {
  display: inline-block;
  float: left;
  margin: 1em 1em 1em 0em;
}

.adpl .select .ship_group_1 label {
  display: block;
}

.select-address .address-to-use,
.select-address .select-menu {
  width: 100%;
  float: left;
}

.bottom_padding {
  padding-bottom: 1em;
}

#checkout_shipping_panel .select-address .select-menu {
  padding-right: 1.3333333em;
}

.gift-card-entry-container {
  .gift-card-number,
  .gift-card-pin {
    input {
      width: 50%;
    }
  }
}

.cv_number_form_field input {
  width: 50%;
}

fieldset.giftwrap .form-item.checkbox label {
  float: left;
}

.checkout .gift-options .giftwrap {
  text-align: left;
  .sub-section,
  h3 {
    float: left;
    width: 100%;
  }
  p {
    float: none;
    min-height: 1em;
    text-align: left;
    width: 100%;
  }
}

.checkout .gift-options .giftwrap-section {
  width: 100%;
}

.checkout .gift-options .gift-ribbon-option {
  float: left;
  width: 24%;
}

.checkout__content,
.checkout__sidebar {
  #bottom-viewcart-buttons,
  #recommended-products-panel,
  #offer-code-panel,
  #links-panel {
    width: 100%;
    float: left;
  }
  #shipmethod-panel {
    width: 100%;
    float: left;
    padding-bottom: 10px;
  }
}

.registration-form-container .password.form-item input[type='password'],
.registration-form-container .text input[type='text'] {
  width: 50%;
}

.promotions-container .checkout__panel--header {
  padding: 0;
}

.checkout__content .adpl .terms_and_conditions_section label[data-required]:before {
  content: '\E00D';
}

.checkout__content
  .adpl
  .terms_and_conditions_section
  input[type='checkbox']:checked
  ~ label:before,
.checkout__content
  .adpl
  .terms_and_conditions_section
  input[type='checkbox']:checked
  ~ .label:before {
  content: '\E00C';
}

.place-order-button {
  margin: 1em 0;
}

#sign-in-confirmation-panel .form-item input {
  width: 50%;
}

body select::-ms-expand {
  display: none;
}

#shipping-panel .new-address-container #qas {
  overflow: hidden;
  width: 100%;
}

#paypal-one-click-confirm-panel {
  .checkout__panel--header {
    border-bottom: 2px solid #dedede;
  }
  .divide {
    padding: 0 20px;
    .content.column {
      float: left;
      width: 50%;
    }
    .checkout__panel--header {
      padding-left: 0;
    }
  }
}

#colorbox {
  /* Pop up samples page */
  #samples-panel {
    .continue-shopping-outer-container {
      .sample_popup_buttons {
        display: block;
      }
      .sample_page_buttons {
        display: none;
      }
    }
  }
}

#samples-panel {
  .product-list li.product .skus li.sku {
    width: 100%;
    a {
      width: 100%;
      display: block;
    }
  }
  .samples-overlay .product img {
    float: none;
  }
  .continue-shopping-outer-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 50px 0;
    .continue-shopping-inner-container {
      a {
        float: left;
        line-height: 54px;
        @include breakpoint($medium-down) {
          line-height: inherit;
        }
      }
      .mobile_hidden a {
        color: $color-white;
        font-family: $font--futura-demi;
        font-size: 24px;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block;
        width: 10em;
        padding: 0.5em;
      }
      .add-button.mobile_hidden {
        display: block;
      }
      .continue-buttons.pc_hidden {
        display: none;
      }
      .sample_popup_buttons {
        display: none;
      }
      .sample_page_buttons {
        display: inline-block;
        .continue-buttons {
          padding-bottom: 0;
        }
      }
    }
  }
  .samples-panel {
    &__content {
      max-width: 1280px;
      width: auto;
      .product-list-container {
        border-top: 1px solid $color-light-gray;
        border-bottom: 1px solid $color-light-gray;
        .selected {
          .skus {
            .button {
              color: $color-dark-gray;
              background-color: $color-white;
              position: relative;
              &:before {
                border: 1px solid $color-light-gray;
                display: block;
                background: none;
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                pointer-events: none;
              }
            }
          }
        }
      }
      .checkout__panel--heading {
        padding-left: 10px;
        margin-bottom: 17px;
      }
      .remaining {
        padding-left: 10px;
        .available {
          letter-spacing: 0.01em;
          margin-bottom: 22px;
        }
      }
    }
  }
}

.ship-method-group .date-list {
  float: right;
  width: 100%;
  .form-item {
    width: 50%;
    float: right;
    label {
      float: left;
    }
  }
}

#viewcart-panel,
#bottom-viewcart-buttons {
  .continue-buttons {
    width: 100%;
    text-align: right;
  }
  .paypal-checkout {
    &.disabled {
      cursor: default;
      position: relative;
      border: 1px solid grey;
      img {
        opacity: 0.2;
        filter: alpha(opacity=20);
      }
      .paypal-message {
        position: absolute;
        font-size: 0.8em;
        bottom: -33px;
        text-align: left;
        width: 220px;
        left: 0;
      }
    }
  }
  .paypal-message.mobile-only {
    display: none;
    visibility: hidden;
  }
}

.samples-overlay {
  #cboxContent {
    #samples-panel {
      .continue-shopping-outer-container {
        .continue-shopping-inner-container {
          padding-right: 10px;
        }
        a {
          padding-right: 10px;
          padding-left: 1.5%;
          padding-top: 1%;
          float: left;
        }
      }
      .samples-panel__content {
        width: auto;
        .remaining {
          max-width: 100%;
        }
      }
      ul.product-list {
        max-width: 100%;
        li {
          .product-brief__title {
            height: 35px;
          }
          .product-size {
            height: 15px;
          }
        }
      }
    }
    .product-list {
      li.product {
        &:nth-child(4n + 1) {
          clear: none;
        }
      }
    }
    .product-img {
      float: none;
    }
  }
}

.checkout__panel.gift-options__content {
  float: left;
  width: 100%;
  padding-bottom: 10px;
}

.checkout__content {
  .order-summary-panel__label {
    text-align: left !important;
  }
  .cart-items__item--remove-form {
    a {
      text-decoration: underline;
    }
  }
  .checkout__panel--continue-buttons {
    .continue-buttons {
      position: relative;
      .continue-shopping {
        text-decoration: underline;
        position: absolute;
        left: 0px;
        top: 22px;
        width: auto !important;
      }
    }
  }
  .messages {
    .single-message {
      color: $color-light-green;
    }
    div[id$='cart.qty_limit.reduced'] {
      display: none;
    }
  }
  .cart-items {
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      .cart-items__header--total {
        width: 27% !important;
        text-align: center !important;
        padding-left: 0 !important;
      }
    }
    .cart-items__header--products {
      padding-left: 14%;
      width: 48%;
    }
    .cart-items__header--total {
      width: 32%;
      padding-left: 22%;
    }
    .cart-items__item--thumb {
      width: 12%;
    }
    .cart-items__item--desc {
      width: 35%;
    }
  }
  .recommended-item {
    .recommended-item__product-info__meta-heada {
      height: 90px;
    }
  }
  #review-panel {
    border-top: 1px solid $color-light-gray;
    padding: 0.8em 0 0.6em;
    #checkout_payment {
      .checkout-card-payment {
        .card-expiry-date {
          .card-cvn {
            @include breakpoint(($medium-up) ($medium-down)) {
              padding-right: 0em;
            }
            a {
              font-family: $font--futura-book;
              letter-spacing: 0.04em;
              font-weight: normal;
              line-height: 1;
              font-size: 14px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
    .address {
      padding: 0 1em;
      .view-address {
        a.change-address {
          margin-left: 5px;
          font-family: $font--futura-book;
          letter-spacing: 0.04em;
          font-weight: normal;
          line-height: 1;
          font-size: 14px;
          text-transform: uppercase;
        }
      }
      .same-as-shipping {
        display: none !important;
      }
    }
    .checkout__panel--heading {
      margin-bottom: 0;
    }
    .checkout__panel--header {
      .checkout__panel--heading {
        #payment-information {
          display: none;
        }
      }
      #checkout_billing_panel {
        .form-container {
          .sub-section {
            .bottom_padding {
              #form--checkout_saved_payments--field--PAYMENT_TOKEN {
                clear: both;
              }
            }
          }
        }
      }
    }
    .terms_and_conditions_section {
      padding: 1em;
      border-top: 1px solid $color-light-gray;
    }
    .place-order-button {
      margin-left: 1em;
    }
  }
}

.payment-address,
.shipping-address-display {
  line-height: 1.4em;
}

.payment-option {
  .related-media {
    margin-top: 12px;
    top: 10px;
    position: relative;
    img {
      margin-right: 10px;
      display: inline-block;
    }
  }
  label {
    cursor: pointer;
  }
}

@media (min-width: 1024px) {
  .page-header {
    .page-header__meta-nav {
      .page-header__meta-nav__inner {
        padding: 0 0;
      }
    }
    .page-header__nav {
      .page-header__nav__inner {
        padding: 0 0;
      }
    }
  }
}

.page-wrapper {
  .checkout {
    max-width: none;
    margin: 0 0;
  }
}

#shipmethod-panel {
  .ship-method-group-label {
    label {
      float: none;
      .overlay-wrapper {
        display: inline;
        .click-and-collect {
          text-decoration: underline;
        }
      }
    }
  }
  .ship-method-home-group-container {
    width: 100%;
  }
}

#confirmation-page {
  #confirmation-panel {
    .confirmation-panel__print-buttons {
      a {
        text-decoration: underline;
      }
    }
  }
}

.product-sku-price {
  span {
    display: block;
  }
}

.checkout__panel {
  padding: 1em 0.5em;
}

.need_links {
  text-transform: uppercase;
  font-size: 15px;
  color: $color-darker-gray;
}

.need_links h2 {
  padding-left: 10px;
  color: $color-darker-gray;
}

.need_links div {
  float: left;
  width: 100%;
  padding-bottom: 15px;
  padding-left: 10px;
}

.need_links a {
  text-decoration: underline;
  padding-right: 5px;
}

#review-panel {
  .content {
    .checkout__panel--header {
      margin-top: 10px;
    }
  }
}

.checkout {
  .checkout__content {
    #review-panel {
      #checkout_billing_panel {
        .form-container {
          .form-item {
            span.payment-option {
              float: left;
              width: 33%;
              padding-bottom: 1em;
            }
          }
        }
      }
    }
  }
  .checkout__panel {
    .clearpay {
      float: right;
      text-align: right;
      margin-bottom: 0.5em;
      .clearpay-image {
        vertical-align: text-top;
        height: 23px;
      }
    }
  }
  &.checkout-single-page {
    .review-panel {
      .payment-options {
        border-top: 1px solid #{$color-light-gray};
        padding: 25px 0;
        margin: 0;
        .checkbox-label {
          text-transform: uppercase;
        }
        input[type='radio'] {
          & ~ label {
            cursor: pointer;
            &:before {
              margin-right: 5px;
              vertical-align: top;
              margin-top: 2px;
            }
          }
        }
        .card-text {
          margin-left: 5px;
        }
      }
      .payment-option {
        input[type='radio'] {
          & ~ label {
            &:before {
              vertical-align: top;
            }
          }
        }
      }
      .card-icons {
        margin-bottom: 15px;
      }
      .paypal-image {
        height: 20px;
        vertical-align: top;
      }
      .afterpay-image {
        height: 30px;
        vertical-align: bottom;
        position: relative;
        top: 2px;
      }
      .payment-heading {
        display: block;
        padding-bottom: 15px;
      }
      .afterpay-option {
        border-bottom: 1px solid #{$color-light-gray};
        line-height: 1.4;
        input[type='radio'] {
          & ~ label {
            &:before {
              margin-top: 8px;
            }
          }
        }
      }
      .submit {
        .clearpay-submit {
          width: 202px;
          text-align: left;
          background: #{$color-black} url(/media/images/checkout/clearpay-logo-white-transparent.svg)
            no-repeat 100% 53%;
          background-size: 118px 21px;
          &:hover {
            background: #{$color-green} url(/media/images/checkout/clearpay-logo-white-transparent.svg)
              no-repeat 100% 53%;
            background-size: 118px 21px;
          }
        }
      }
      .afterpay-disclaimer {
        text-decoration: underline;
        margin-left: 10px;
      }
      .view-address {
        padding-top: 1em;
      }
      &.adpl {
        .terms-section {
          input[type='checkbox'] {
            & ~ label {
              @include icon('checkbox', before);
            }
            &:checked {
              & ~ label {
                @include icon('checkbox--checked', before);
              }
            }
          }
        }
      }
    }
    .clearpay {
      display: none;
    }
  }
  .afterpay-learnmore {
    span {
      width: 20px;
      height: 20px;
      border: 1px solid #{$color-black};
      border-radius: 50%;
      font-size: 20px;
      font-family: $font--futura-medium;
      line-height: 1;
    }
  }
}

.form-item.card-expiry-date {
  .payment-form__item {
    width: 33.2%;
  }
}

.checkout__sidebar {
  #viewcart-panel {
    .checkout__panel--header {
      padding-top: 1em;
    }
    .cart-items.products {
      .product.donation {
        .cart-items__item--desc {
          height: 50px;
        }
      }
    }
  }
  #samples-pane {
    #samples-panel {
      .offer {
        .checkout__panel--heading {
          margin-top: 0;
        }
      }
    }
  }
}

#shipping-panel {
  &.substate-manual {
    #manual-address-fields {
      display: block !important;
      visibility: visible !important;
    }
  }
  #checkout_shipping_panel {
    #continue-btn {
      width: 40%;
      padding-left: 0.5em;
      input[type='submit'] {
        width: 100%;
      }
    }
  }
  input[type='checkbox'] ~ label,
  input[type='checkbox'] ~ .label {
    display: inline-block;
  }
}

#registration-panel {
  #checkout_registration {
    .create-account {
      width: 40%;
      input[type='submit'] {
        width: 100%;
      }
    }
  }
}

.checkout {
  .promotions-container {
    padding: 1em 0 0 1em;
  }
  .terms {
    padding-left: 1em;
  }
  .form-item {
    .note {
      margin-bottom: 0.3em;
    }
  }
  .checkout__content {
    #guarantee-panel {
      padding-left: 0;
    }
  }
}

#confirmation-page {
  #completed_checkout_registration {
    .create-account {
      width: 40%;
      input[type='submit'] {
        width: 100%;
      }
    }
  }
}

.product-sku-price {
  .product-sku-price__value:first-child {
    font-size: 20px;
  }
}

#review-panel {
  .content {
    .gift-cards.bottom_padding {
      margin-left: 1em;
      margin-top: 1em;
      padding-bottom: 0px;
    }
  }
}

#security-code-panel {
  padding: 0 5%;
}

#max-quantity-container {
  padding: 25px;
}

#order-summary-panel {
  .checkout__panel--content {
    .giftwrap.label {
      float: left;
      width: 65%;
      clear: both;
      margin-bottom: 0.5em;
      text-transform: uppercase;
      font-family: $font--futura-demi;
      font-size: 21px;
    }
    .giftwrap.value {
      float: right;
    }
  }
}

.checkout__content {
  .cart-items__item--qty {
    text-align: center;
    .selectBox {
      .selectBox-label {
        line-height: inherit;
        min-width: 0.6em;
      }
    }
  }
}

@media (max-width: 1024px) {
  #samples-panel {
    .continue-shopping-outer-container {
      .continue-shopping-inner-container {
        padding: 15px 0 30px;
        text-align: right !important;
      }
    }
  }
}

.checkout__content {
  .address-form {
    #qas {
      .form-item.manual-address {
        margin-top: 0.7%;
        padding-right: 0;
        margin-top: 1em;
      }
    }
    #manual-address-fields {
      .form-item.country-id {
        margin-top: 2.3%;
      }
    }
  }
}

#colorbox {
  #cboxContent {
    #cboxLoadedContent {
      padding-right: 2%;
    }
  }
  &.helpful-links-overlay {
    #cboxWrapper {
      #cboxContent {
        #cboxLoadedContent {
          padding: 2em;
        }
      }
    }
  }
  &.cboxIframe {
    #cboxContent {
      // IDs used here to override the base changes
      @include breakpoint($landscape-up) {
        height: 645px !important; // !important used here to override the inline styles
      }
      #cboxLoadedContent {
        padding: 15px;
        width: 100% !important; // !important used here to override the inline styles
        iframe {
          max-height: 100%;
        }
      }
      #cboxClose {
        top: 2px;
        right: 0;
        &:hover {
          background-position: 0;
        }
      }
    }
  }
}

.checkout {
  .checkout__content {
    #sign-in-panel {
      padding-left: 0px !important;
      @include breakpoint($medium-up) {
        .form-item {
          &.submit {
            bottom: 0;
          }
        }
      }
    }
    #shipping-panel {
      padding-left: 0px !important;
    }
  }
}

.select-address {
  .address-to-use label {
    text-transform: none;
  }
}

.checkout__panel--continue-buttons {
  .paypal-checkout {
    display: inline;
    margin-right: 10px;
  }
  .paypal_mb {
    display: none !important;
  }
  .paypal_pc {
    width: 184px;
    height: 48px;
    margin-bottom: 2px;
    border: 1px solid black;
    text-align: center;
    float: right;
    img {
      height: 40px;
      margin: 0 auto;
      padding-top: 9px;
    }
  }
  .continue-checkout {
    &.disabled {
      &:hover {
        background-color: $color-black;
      }
      cursor: default;
      opacity: 0.5;
    }
    width: 184px;
    line-height: 42px;
    margin-bottom: 2px;
    float: right;
  }
}

#checkout_shipping_panel {
  .checkout__panel {
    border-top-width: 0;
    padding: 1em 1em;
    .checkout__panel--header {
      padding: 0;
    }
  }
  .gift-options {
    padding: 0 1em;
  }
}

#review-panel {
  #checkout_saved_payment {
    .cv_number_form_field {
      a {
        font-family: $font--futura-book;
        letter-spacing: 0.04em;
        font-weight: normal;
        line-height: 1;
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }
}

.adpl {
  .invalid_marker {
    font-family: 'Arial Unicode MS';
    bottom: 28px;
  }
}

.checkout__content {
  .cart-items__header--qty {
    width: 10%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  #map-foreground-node {
    .map-container {
      .address-map-search-panel {
        width: 55%;
        .search-box-wrapper {
          .search-box {
            width: 100%;
            input {
              width: 55%;
            }
          }
        }
      }
      .search-box-cancel {
        left: 28em;
        top: 0;
      }
    }
  }
}

.generic-helplinks-overlay {
  overflow: scroll !important;
  #cboxWrapper {
    top: 105px;
  }
}

.generic-helplinks-overlay::-webkit-scrollbar {
  background: transparent;
}

.generic-helplinks-overlay::-webkit-scrollbar {
  display: none;
}

@media (min-width: 768px) {
  .checkout {
    .checkout__sidebar {
      display: none;
    }
    .checkout__content {
      float: left;
      width: 100%;
      border-bottom: none;
      border-right: 1px solid $color-light-gray;
      .cart-items__header--total {
        width: 24%;
        padding-left: 12%;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .checkout {
    .checkout__content {
      .cart-items__header--total {
        width: 24% !important;
        padding-left: 12% !important;
      }
    }
  }
}

@media (min-width: 769px) {
  .checkout__sidebar {
    display: block !important;
  }
  .checkout__content {
    float: left;
    width: 66.6666667% !important;
    border-bottom: none;
    border-right: 1px solid $color-light-gray;
    .cart-items__header--total {
      width: 32% !important;
      padding-left: 22% !important;
    }
  }
}

.helpful-links-overlay {
  #cboxLoadedContent {
    padding: 2em;
  }
}

a.overlay-link-cms.loading {
  position: static;
  opacity: 1;
  background: url(/media/images/checkout/loadingicon.gif) right center no-repeat;
  background-size: 15px;
  padding-right: 1.5em;
  &:before {
    display: none;
  }
}

.checkout__content {
  #bottom-viewcart-buttons {
    .viewcart-buttons-panel {
      .checkout__panel--continue-buttons {
        text-align: left;
        a.continue-shopping {
          display: inline-block;
          float: left;
          width: auto;
          height: 48px;
          line-height: 48px;
          margin: 0;
          vertical-align: top;
          text-decoration: underline;
        }
        .continue-buttons {
          width: auto;
          .continue-checkout,
          .paypal-checkout.paypal_pc {
            display: inline-block;
            margin: 0;
          }
          .paypal-checkout.paypal_pc {
            margin-right: 4%;
            @media (max-width: 767px) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

/*** ApplePay Integration ***/

/* User NOT signed in */

.viewcart-buttons-panel {
  &.applepay {
    .continue-buttons {
    }
    .applepay-integration-wrapper {
      display: inline-block;
      clear: both;
      width: 100%;
      margin: 15px 0 0;
      box-sizing: border-box;
      padding: 10px 13px;
      border: 1px solid #b7b7b7;
      background: #ebebeb;
      &.applepay-error {
        border-color: $color-red;
        background: #ffe2e4;
      }
      .applepay-button-container {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        .apple-pay-button {
          display: block;
          overflow: hidden;
          text-indent: 100%;
          white-space: nowrap;
          -webkit-appearance: -apple-pay-button;
          -apple-pay-button-style: $color-black;
        }
        .apple-pay-button.button-style-buy {
          -apple-pay-button-type: buy;
        }
        .apple-pay-button.button-style-set-up {
          -apple-pay-button-type: set-up;
        }
      }
      div.applepay-button-container {
        width: 65%;
        padding-right: 20px;
        box-sizing: border-box;
        text-align: right;
        .divide {
          position: relative;
          padding-right: 35px;
          font-size: 14px;
          label.terms_input:before {
            position: absolute;
            right: 0;
            left: auto;
            top: calc(50% - 9px);
            margin: 0;
          }
          .label-content {
            margin: 0;
            text-align: justify;
            a {
              display: inline !important;
              text-decoration: underline;
            }
          }
        }
      }
      span.applepay-button-container {
        width: 35%;
        text-align: center;
      }
    }
  }
}

.page-header__meta-nav {
  .page-header__utils {
    @include breakpoint($landscape-up) {
      .node {
        float: left;
      }
    }
  }
}
/* User signed-in */

.elc-user-state-logged-in {
  .checkout__content {
    #bottom-viewcart-buttons {
      .viewcart-buttons-panel {
        &.applepay {
          .applepay-integration-wrapper {
            display: block;
            float: right;
            clear: none;
            width: auto;
            height: 48px;
            margin: 0 4% 0 0;
            padding: 0;
            border: 0 none;
            background: transparent;
            .applepay-button-container {
              width: auto;
              padding: 0;
            }
            div.applepay-button-container {
              display: none;
            }
            span.applepay-button-container {
              display: block;
              width: 184px;
              a.apple-pay-button {
                height: 48px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1260px) {
    .checkout__content {
      #bottom-viewcart-buttons {
        .viewcart-buttons-panel {
          &.applepay {
            .checkout__panel--continue-buttons {
              .continue-buttons {
                width: 188px;
                float: right;
                .continue-checkout,
                .paypal-checkout.paypal_pc {
                  display: block;
                  width: 184px !important;
                  height: 48px;
                  margin: 0 0 20px !important;
                  padding: 0 !important;
                }
                .paypal-checkout.paypal_pc {
                  padding-top: 0 !important;
                  img {
                    margin-top: 4px !important;
                    padding: 0;
                  }
                }
              }
              .applepay-integration-wrapper {
                float: none;
                clear: both;
                margin: 0;
                span.applepay-button-container {
                  float: right;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .checkout__content {
      #bottom-viewcart-buttons {
        .viewcart-buttons-panel {
          &.applepay {
            .checkout__panel--continue-buttons {
              .continue-buttons {
                float: none;
                margin: auto;
                .paypal-checkout.paypal_pc {
                  img {
                    width: 90%;
                    height: auto;
                  }
                }
              }
              .applepay-integration-wrapper {
                width: 188px;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}

body[data-checkout-release='1'] {
  .pg_wrapper--checkout {
    .confirm-progress-bar {
      display: block;
    }
  }
}
