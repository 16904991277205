/* NOTIFICATIONS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
#bt_notification div.bt-content {
  position: relative;
  border: 1px solid $color-black;
  padding: 15px;
  background-color: $color-white;
  z-index: 1001;
  font-size: 12px;
  line-height: 16px;
}

#bt_notification div.bt-content a.bt-close-link {
  position: absolute;
  top: 10px;
  right: 10px;
}
/* CUSTOM POSITIONING */
#bt_notification div.bt-bottom-right {
  position: fixed;
  bottom: 0px;
  right: 10px;
  width: 250px;
  border: 1px solid $color-black !important;
  .bt-settings {
    color: $color-red !important;
  }
  .bt-find-more {
    text-decoration: none !important;
    color: $color-red !important;
    font-size: 14px;
    border-bottom: 1px solid $color-red;
    margin-top: 5px;
    display: inline-block;
  }
}

#bt_notification div.bt-bottom-full {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-bottom-width: 0;
}
/* DEVICES */
#bt_notification div.bt-content-mobile {
  padding: 10px;
}

#bt_notification div.bt-content-mobile a.bt-close-link {
  display: none;
}
/* BRAND SPECIFICS @JM */
#bt_notification div.bt-content {
  border-color: $color-black;
  border-width: 10px;
  padding: 20px 25px 20px 20px;
}

#bt_notification div.bt-content a {
  color: $color-black;
  font-family: $font--futura-medium;
}

#bt_notification div.bt-content a.bt-close-link {
  display: block;
  width: 15px;
  height: 15px;
  background: url(/media/export/images/cookies/close_x.png) 50% 50% no-repeat;
  text-indent: -9999em;
}

#bt_notification div.bt-content {
  h6 {
    font-size: 1.17em;
    margin: 0 0 5px;
  }
  .bt-pp-section {
    margin-bottom: 10px;
  }
  .button {
    color: $color-white;
    font-size: 18px;
    display: block;
    margin-top: 10px;
    padding: 5px;
  }
}
/* SETTINGS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
#bt_settings div.bt-content {
  border: 1px solid black;
  padding: 15px;
  background-color: $color-white;
  z-index: 1001;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 1.5em;
}

#bt_settings div.bt-content div.bt-intro {
  display: block !important;
}

#bt_settings div.bt-content div.bt-intro div.bt-intro-copy {
  display: block !important;
}

#bt_settings div.bt-content div.bt-intro div.bt-intro-copy p {
  margin-top: 1em;
}

#bt_settings {
  display: block !important;
}
/* TOGGLE SLIDER */
#bt_settings div.bt-content div.slide,
#bt_settings div.bt-content ul.slide-toggle {
  position: relative;
  overflow: hidden;
  width: 525px;
}

#bt_settings div.bt-content div.slide {
  margin: 20px 0;
  display: block !important;
}

#bt_settings div.bt-content ul.slide-toggle {
  list-style: none;
  margin: 0;
  padding: 0;
  background: transparent url('/media/export/images/cookies/all-off.png') 50% 100% no-repeat;
  background-size: contain;
}

#bt_settings div.bt-content div.mask {
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: 0px;
  display: block !important;
}

#bt_settings div.bt-content div.mask ul.slide-toggle {
  background-image: url('/media/export/images/cookies/all-on.png');
}

#bt_settings div.bt-content div.mask div.drag {
  position: absolute;
  bottom: 12px;
  right: 70px;
  width: 26px;
  height: 11px;
  background: transparent url('/media/export/images/cookies/icon_arrow.png') 50% 100% no-repeat;
}

#bt_settings div.bt-content ul.slide-toggle li {
  cursor: pointer;
  float: left;
  width: 160px;
  height: 138px;
  list-style-type: none;
}

#bt_settings div.bt-content ul.slide-toggle li.middle {
}

#bt_settings div.bt-content ul.slide-toggle li.selected {
}

#bt_settings div.bt-content ul.slide-toggle li span {
  display: block;
  font-family: $font--futura-medium;
  text-align: center;
  margin: 5px;
}
/* PRIVACY INFORMATION */
#bt_settings div.bt-content .bt-privacy-info {
  display: none;
  overflow: hidden;
}

#bt_settings div.bt-content .bt-privacy-info.selected {
  display: block !important;
}

#bt_settings div.bt-content .bt-privacy-info h3 {
  padding: 1em 1em 1em 40px;
  background-position: 10px 50%;
  background-repeat: no-repeat;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-will h3 {
  background-image: url('/media/export/images/cookies/icon_safe_lock.png');
  font-size: 20px;
  font-style: italic;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-willnot h3 {
  background-image: url('/media/export/images/cookies/icon_unsafe_lock.png');
}

#bt_settings div.bt-content .bt-privacy-info div {
  width: 48%;
  margin-right: 2%;
  float: left;
  display: block !important;
}

#bt_settings div.bt-content .bt-privacy-info div ul {
  list-style-type: none;
  padding-left: 0;
  margin: 1em;
  padding-left: 0px !important;
}

#bt_settings div.bt-content .bt-privacy-info div ul li {
  margin: 0.8em 0;
  padding-left: 30px;
  background-position: 0 50%;
  background-repeat: no-repeat;
  list-style: none;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-will ul li {
  background-image: url('/media/export/images/cookies/icon_safe_thick.png');
  font-size: 18px;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-willnot ul li {
  background-image: url('/media/export/images/cookies/icon_unsafe_thick.png');
  font-size: 18px;
}
/* CUSTOM POSITIONING */

/* DEVICES */
#bt_settings div.bt-content-mobile ul.slide-toggle {
  background-image: url('/media/export/images/cookies/m_all-off.png');
}

#bt_settings div.bt-content-mobile div.mask ul.slide-toggle {
  background-image: url('/media/export/images/cookies/m_all-on.png');
}

#bt_settings div.bt-content-mobile div.slide {
  margin: 20px auto;
}

#bt_settings div.bt-content-mobile div.slide,
#bt_settings div.bt-content-mobile ul.slide-toggle {
  width: 288px;
}

#bt_settings div.bt-content-mobile div.mask div.drag {
  right: 36px;
}

#bt_settings div.bt-content-mobile ul.slide-toggle li span {
}

#bt_settings div.bt-content-mobile .bt-privacy-info div {
  width: 100%;
  float: none;
}
/* BRAND SPECIFICS */
#bt_settings div.bt-content {
  border-color: $color-black;
  border-width: 2px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

#bt_settings div.bt-content-desktop {
  padding: 2em;
  height: 100%;
  display: block !important;
}

#bt_settings div.bt-content-mobile {
  display: block !important;
}

#bt_settings div.bt-content div.bt-intro h2 {
  padding: 0;
  margin: 0 0 1em 0;
  border: none;
  text-transform: none;
  color: $color-black;
  font-size: 34px;
  font-style: italic;
  font-weight: normal;
}

#bt_settings div.bt-content ul.slide-toggle li span {
  display: none;
}

#bt_settings div.bt-content .bt-privacy-info h3 {
  background-color: $color-light-gray;
}

#bt_settings div.bt-content-mobile div.slide,
#bt_settings div.bt-content-mobile ul.slide-toggle {
  width: 246px;
  padding-left: 0 !important;
}

#bt_settings div.bt-content-mobile ul.slide-toggle li {
  width: 82px;
  height: 136px;
}

#bt_settings div.bt-content-mobile div.mask div.drag {
  right: 30px;
  bottom: 11px;
}

.tealium {
  display: none !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 736px) {
  #bt_settings div.bt-content ul.slide-toggle {
    width: 246px;
    background-size: 246px 147px;
    padding-left: 0px !important;
  }
  #bt_settings div.bt-content ul.slide-toggle li {
    width: 82px;
    height: 148px;
  }
  #bt_settings div.bt-content .bt-privacy-info div {
    width: 100%;
  }
  #bt_settings div.bt-content div.mask div.drag {
    right: 30px;
  }
}
