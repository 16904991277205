.product-full {
  &__carousel {
    &__thumbs {
      display: none;
      @include breakpoint($spp-medium-up) {
        margin-top: 0;
        display: block;
        width: 100%;
      }
    }
    &__thumb {
      display: inline-block;
      margin-right: 3%;
      margin-bottom: 0;
      &__link {
        border: none;
        display: block;
      }
    }
  }
}
