#csr_header {
  top: 90px;
  a {
    display: block;
  }
  table {
    table-layout: auto;
  }
  #csr_info_box {
    border-right: 1px solid #ff0000;
  }
  #csr_registered_user_info,
  #cust_cart {
    border-right: 1px solid #ff0000;
  }
  .csr_table {
    td {
      vertical-align: middle;
      padding: 0 20px;
    }
    #csr_dragger {
      cursor: move;
      background: #ff0000;
      padding: 0;
      width: 40px;
    }
  }
}
