//One Trust Implementation Changes
.optanon-status {
  &-editable,
  &-on {
    input[type='checkbox'] {
      & ~ label {
        &:before {
          display: none;
        }
      }
    }
  }
}

.cookie-settings-ot {
  .optanon-show-settings-popup-wrapper {
    height: 35px;
  }
}
