/* GNAV styling */
.gnav-util__content__inner {
  .social-login {
    &__container {
      padding: 5px 0 30px 0;
    }
    .tooltip {
      margin: 0;
      padding: 5px 26px 3px;
      .tooltiptext-over {
        bottom: 110%;
      }
    }
  }
}
/* checkout page */
.checkout.checkout-single-page {
  .checkout__content {
    #sign-in-panel.checkout__panel {
      .social-login {
        &__contents {
          padding: 0 18px;
        }
        &__container {
          clear: both;
          padding-top: 50px;
        }
        @include breakpoint($medium-up) {
          margin-top: 45px;
          padding-top: 18%;
          &__divider {
            margin: 18px 0;
          }
        }
        &__email-opt-in,
        &__terms {
          margin: auto 0;
        }
      }
    }
  }
}
/* order confirmation */
.checkout-confirmation-page {
  #confirmation-panel {
    .social-login {
      font-size: 18px;
      &__contents {
        padding: 0;
      }
      .tooltip {
        margin: 8px 0 0;
      }
      .tooltip.terms-tooltip {
        display: none;
      }
      &__email-opt-in,
      &__terms {
        display: block;
        font-size: 18px;
      }
    }
  }
}
/* profile preferences */
.profile-page {
  .social-info {
    &__connect {
      margin: 18px 0 15px;
      .fb-overlay-social-info {
        display: inline;
      }
      .fb_text {
        margin-right: 6px;
        font-size: large;
      }
      .fb_text,
      .fb-overlay-social-info {
        float: left;
      }
      .fb-disclaimer-error {
        padding-bottom: 3px;
      }
    }
    .social-login__terms {
      padding: 0;
      margin: 5px 0;
      display: inline-block;
      width: 100%;
      padding-top: 15px;
      .fb-disclaimer {
        font-size: large;
      }
    }
  }
}
/* GDPR */
.fb-disclaimer-error {
  color: $color-red;
  padding-bottom: 10px;
}

.social-login {
  font-size: 14px;
  &__contents {
    padding: 0 26px;
    margin: 0;
    .social-login__terms {
      padding: 0;
    }
    .social-login__email-opt-in {
      margin: 0;
    }
  }
  /* Tooltip CSS */
  .tooltip {
    text-align: left;
    margin: 5px 0 3px;
    margin-bottom: 0;
    .tooltiptext-over {
      bottom: 130%;
      a {
        text-decoration: underline;
      }
    }
  }
  label {
    text-transform: none;
    margin-top: 5px;
    text-align: left;
  }
  &__terms,
  &__email-opt-in {
    position: relative;
  }
  input[type='checkbox'] ~ label {
    margin-left: 22px;
    &:before {
      position: absolute;
      left: 0;
    }
  }
}

.social-login.gnav {
  input[type='checkbox'] ~ label.fb-disclaimer {
    &:before {
      left: 26px;
    }
  }
}

.fb-overlay-container {
  width: 265px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.fb-overlay-social-info {
  width: 164px;
  height: 22px;
  position: relative;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.write_a_review__login {
  .social-login {
    &__terms {
      padding: 0;
      margin-left: 0;
      margin-right: 0;
    }
    &__email-opt-in {
      margin: 0;
    }
  }
}
