.waitlist-signup-container {
  width: 100%;
  .signup-block {
    margin: 0 auto;
    max-width: 680px;
    padding: 3em 0 3em 0;
  }
  .waitlist_signup_text {
    float: left;
    width: 50%;
    @include breakpoint($small-down) {
      float: none;
      width: 100%;
    }
    padding: 0 1.6em 0 1.6em;
    .title-text {
      p {
        margin-bottom: 0em;
        margin-top: 0.2em;
        font-style: italic;
        font-size: 3.2em;
        font-weight: normal;
        text-align: center;
      }
    }
    .email-teaser-text {
      padding-top: 0.5em;
      p {
        font-size: 0.8em;
        text-align: center;
        line-height: 1.4em;
      }
      .waitlist_terms_link {
        font-size: 0.7em;
        a {
          text-decoration: none;
        }
      }
    }
  }
  .waitlist_signup_fields {
    float: left;
    width: 50%;
    @include breakpoint($small-down) {
      float: none;
      width: 100%;
    }
    .form-item {
      padding: 0.5em;
      &.submit-button {
        margin-top: 2em;
      }
    }
    input {
      width: 100%;
    }
    .email-optin {
      .signin-block__checkbox__text:before {
        background-color: $color-white;
      }
      .signin-block__checkbox--terms {
        float: left;
      }
      .email_signup_copy {
        float: left;
        .email_signup_tickbox {
          float: left;
          max-width: 10%;
        }
        .email_signup_copy {
          float: left;
          max-width: 80%;
        }
        p {
          margin-top: 0em;
          font-size: 0.7em;
        }
      }
    }
  }
}
//waitlist tooltip
.waitlist-tooltip {
  text-align: left;
  padding-left: 30px;
}
