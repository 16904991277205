$base-theme-path: '../../or_base/';

// enable Appointment Booking styling
$appt_booking: true;

@import 'overrides/overrides';
@import '../../or_base/scss/main';
@import 'sections/_gwp_pc';
@import 'sections/pc/account/account';
@import 'sections/pc/checkout/checkout';
@import 'sections/pc/appointment_booking';
@import 'sections/email_signup';

// Click and Collect
@import 'sections/checkout/clickcollect';

// Phone Order
@import 'sections/phone_order';
@import 'sections/recommended_section';

//Store Locator
@import 'sections/variable';
@import 'sections/_store_locator';

//Waitlist
@import 'sections/_waitlist';

//power review
@import 'sections/_power_review';

// CMS Tabbed Subnav
@import 'sections/pc/navigation/subnav';

// GNAV Kit Items
@import 'sections/_gnav_item_kits';

// UK overrides
@import 'template_api/products/product_full';

//privacy popup
@import 'sections/bt';

// Password field Tooltip for GDPR complaint criteria
@import 'sections/_gdpr_validate';

//One Trust Implementation
@import 'sections/cookie_setting_ot';

//social login
@import 'sections/_social_login.scss';
@import 'sections/all';

// Sitewide styles for UK
.content-full-width-padded {
  margin: 0 auto;
  max-width: 720px;
  padding-bottom: 2em;
  h2 {
    font-family: $font--futura-demi;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: normal;
    margin-top: 3em;
  }
  p {
    color: #7a7a6b;
  }
} // .content-full-width-padded

.content-2-column {
  max-width: 1280px;
  overflow: hidden;
  margin: 0 auto;
  &__col {
    width: 50%;
    float: left;
    padding: 4em 2em;
  }
  &__heading {
    font-family: $font--futura-demi;
    letter-spacing: 0.05em;
    font-weight: normal;
    margin: 2em 0 0.8em 0;
    font-size: 23px;
  }
  &__link {
    min-width: 250px;
  }
}
/* quickview products with only 1 size should not have a pointer. Bug 265699 */
.product-sku-select {
  &__placeholder {
    cursor: auto;
  }
}
/* fix for adaptive placeholders, unable to click through the label */
.adpl input[type='email'] + label,
.adpl input[type='tel'] + label,
.adpl input[type='password'] + label,
.adpl input[type='text'] + label,
.adpl select + label {
  pointer-events: none;
}
/* Notification and Shop Now CTA to share styling - MPP 279943 */
@media (max-width: 768px) {
  .product-grid .product-brief__footer .product-add-to-waitlist {
    line-height: 20px;
    padding: 5px 20px;
    font-size: 20px;
    width: 100%;
    min-width: 0;
  }
}

@media (min-width: 768px) {
  .product-grid .product-brief__footer .product-add-to-waitlist {
    max-width: 248px;
    margin: 0;
  }
  .section-customer-service-privacy-policy .sidebar-page__content {
    height: auto !important;
    padding: 1em !important;
    border-left: $color-light-gray solid 1px;
  }
  .section-customer-service-privacy-policy .sidebar-page__sidebar {
    border-right: none !important;
  }
}

.sticky-count {
  background: $color-darker-gray;
  position: fixed;
  z-index: 15;
  bottom: -25px;
  padding: 15px;
  text-align: center;
  width: 600px;
  display: block;
  left: 34%;
  color: $color-white;
}

.hours,
.days,
.minutes,
.seconds {
  display: inline-block;
  font-size: 33px;
}

.timetext {
  font-size: 12px;
  margin-top: 12%;
}

.count-block {
  display: inline-block;
  background: $color-black;
  width: 60px;
  height: 60px;
}

.count-info {
  display: inline-block;
}

.left-balloon {
  position: absolute;
  bottom: 75px;
  z-index: 16;
  float: left;
  left: -7%;
}

.right-balloon {
  position: absolute;
  bottom: 100px;
  z-index: 16;
  float: right;
  right: -9%;
}

.gnav-timetext {
  display: inline-block;
  margin-left: 3px;
}

.gnav-days,
.gnav-hours,
.gnav-minutes,
.gnav-seconds {
  display: inline-block;
  font-size: 18px;
}

.gnav-count-block {
  display: inline-flex;
}

// LP inputs
#lpChat .lp_radio_button input[type='radio'],
#lpChat .lp_checkbox_button input[type='checkbox'] {
  display: none !important;
}

.screen-reader-only {
  left: -9999px;
}

.adpl {
  input {
    &[type='email'].js-label-mode + label:before,
    &[type='tel'].js-label-mode + label:before,
    &[type='password'].js-label-mode + label:before,
    &[type='text'].js-label-mode + label:before {
      content: attr(alt);
    }
  }
  select.js-label-mode + label:before {
    content: attr(alt);
  }
}

.quiz-loading-results {
  #colorbox {
    #cboxWrapper {
      #cboxContent {
        #cboxLoadedContent {
          padding: 0;
        }
      }
    }
  }
}
