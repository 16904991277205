.section-sign-up {
  .site-email-signup {
    &--promotion {
      max-width: 85%;
      padding: 30px 0;
      @include breakpoint($medium-up) {
        max-width: 98%;
        padding: 64px 30px 30px;
      }
      .site-email-signup {
        &__fields {
          width: min-content;
          @include breakpoint($medium-up) {
            display: block;
            justify-content: center;
          }
          .email-field {
            margin-bottom: 25px;
            @include breakpoint($medium-up) {
              margin-bottom: 0;
            }
          }
          .label {
            margin-top: 20px;
            @include breakpoint($medium-up) {
              @include swap_direction(margin, 20px 10px 0 20px);
            }
          }
        }
        &__terms-conditions__checkbox {
          @include breakpoint($medium-up) {
            margin: 0 70px 20px;
          }
        }
        &__success {
          padding-top: 36px;
          width: 320px;
          @include breakpoint($medium-up) {
            height: inherit;
          }
        }
        &__submit {
          margin: 0 auto;
          min-width: 210px;
          height: 40px;
        }
        &__copy {
          text-align: center;
        }
      }
    }
  }
  #cboxOverlay {
    &.promotion-signup-thanks {
      background-color: $color-white;
    }
  }
}

.adpl {
  &.adpl-promotion {
    input[type='email'],
    input[type='tel'] {
      border-radius: 0;
      & + label {
        margin-#{$ldirection}: 0;
        @include breakpoint ($medium-up) {
          margin-#{$ldirection}: 20px;
        }
      }
    }
  }
}

.page-header__utils {
  .site-email-signup {
    .gdpr_signup_link {
      &.tooltip {
        &:hover {
          width: 420px;
          .tooltiptext-over {
            margin-#{$ldirection}: -400px;
            max-width: 720px;
            width: 700px;
          }
        }
      }
    }
  }
}

.menu__list {
  .utility-nav {
    &__loyalty {
      .menu--lvl-2 {
        z-index: 99;
      }
    }
  }
}
